export const assetBase = 'https://assets.webveloper.com';

export function assetLink(name: string): string {
  return `${assetBase}/${name}`;
}

export const PHONE_NUMBER_REGEX = /^\(\d{3}\) \d{3}-\d{4}$/i;
export const PHONE_NUMBER_WITH_EXTENSION_REGEX = /^\(\d{3}\) \d{3}-\d{4} Ext\. \d+$/i;

export function cleanPhoneNumber(phoneNumber: string | null): string | null {
  if (!phoneNumber) return phoneNumber;
  if (/ext\.\s+$/i.test(phoneNumber)) {
    return phoneNumber.substring(0, phoneNumber.toLowerCase().indexOf('ext')).trim();
  }
  return phoneNumber.trim();
}

export function validatePhoneNumber(phoneNumber: string | null): boolean {
  const cleanedPhoneNumber = cleanPhoneNumber(phoneNumber);
  if (!cleanedPhoneNumber) return false;
  return PHONE_NUMBER_REGEX.test(cleanedPhoneNumber)
    || PHONE_NUMBER_WITH_EXTENSION_REGEX.test(cleanedPhoneNumber);
}

export const openIntercom = () => {
  if (window.Intercom) {
    window.Intercom('show');
    window.Intercom('update', {
      hide_default_launcher: true,
    });
  }
};
