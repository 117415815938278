import React, { CSSProperties } from 'react';
import cx from 'clsx';
import { createUseStyles, useTheme } from 'react-jss';
import { Theme } from 'src/theme';
import WithUnderline from './WithUnderline';

export interface Props {
  variant: 'h1' | 'h2' | 'h3' | 'p1' | 'p2' | 'p3';
  color: 'teal' | 'blue' | 'blue-secondary' | 'textPrimary' | 'textSecondary' | 'blackish' | 'white' | 'error' | 'inherit',
  underline?: boolean,
  centered?: boolean,
  className?: string,
  underlineClassName?: string,
  style?: CSSProperties,
}

interface StyleProps {
  variant: Props['variant'],
  color: string,
  centered: boolean,
}

const useStyles = createUseStyles<'base' | Props['variant'], StyleProps, Theme>((theme) => ({
  base: ({ color, centered }) => ({
    fontFamily: theme.typography.fontFamily,
    color,
    textAlign: centered ? 'center' : undefined,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  }),
  // These need to be explicitly written as separate classes for media queries to work properly
  h1: {
    ...theme.typography.variants.h1,
  },
  h2: {
    ...theme.typography.variants.h2,
  },
  h3: {
    ...theme.typography.variants.h3,
  },
  p1: {
    ...theme.typography.variants.p1,
  },
  p2: {
    ...theme.typography.variants.p2,
  },
  p3: {
    ...theme.typography.variants.p3,
  },
}));

const Typography: React.FC<Props> = ({
  variant,
  underline,
  className,
  underlineClassName,
  color,
  centered = false,
  children,
  style,
}) => {
  const theme: Theme = useTheme();
  const calculatedColor = (() => {
    switch (color) {
      case 'teal': {
        return theme.palette.primary.TEAL;
      }
      case 'blue': {
        return theme.palette.primary.BLUE;
      }
      case 'blue-secondary': {
        return theme.palette.secondary.BLUE;
      }
      case 'error': {
        return theme.palette.primary.ERROR;
      }
      case 'textPrimary': {
        return theme.palette.text.PRIMARY;
      }
      case 'textSecondary': {
        return theme.palette.text.SECONDARY;
      }
      case 'white': {
        return theme.palette.primary.WHITE;
      }
      case 'blackish': {
        return theme.palette.text.BLACKISH;
      }
      case 'inherit': {
        return 'inherit';
      }
      default: {
        return theme.palette.text.PRIMARY;
      }
    }
  })();
  const classes = useStyles({
    variant,
    color: calculatedColor,
    centered,
  });
  const props = {
    className: cx(classes.base, classes[variant], className),
    style,
  };
  const textComponent = (() => {
    switch (variant) {
      case 'h1': {
        return <h1 {...props}>{children}</h1>;
      }
      case 'h2': {
        return <h2 {...props}>{children}</h2>;
      }
      case 'h3': {
        return <h3 {...props}>{children}</h3>;
      }
      case 'p1':
      case 'p2':
      case 'p3': {
        return <p {...props}>{children}</p>;
      }
      default: {
        return <p {...props}>{children}</p>;
      }
    }
  })();

  if (underline) {
    return (
      <WithUnderline centered={centered} className={underlineClassName}>
        {textComponent}
      </WithUnderline>
    );
  }

  return textComponent;
};

export default Typography;
