import React from 'react';
import cx from 'clsx';
import { Link as GatbsyLink } from 'gatsby';

type LinkColor = 'blue' | 'teal';

interface Props {
  color?: LinkColor,
  href: string,
  onClick?: () => void,
  isExternal?: boolean,
  className?: string,
  style?: React.CSSProperties,
  isUnderlined?: boolean,
}

const Link: React.FC<Props> = ({
  color,
  href,
  isExternal = false,
  className,
  style,
  isUnderlined = true,
  onClick,
  children,
}) => {
  const classNames = cx([
    color === 'blue' && [
      'text-blue',
      'hover:text-blue-hover',
    ],
    color === 'teal' && [
      'text-teal',
      'hover:text-teal-hover',
    ],
    isUnderlined && 'underline',
    isUnderlined && 'hover:underline',
  ], className);
  if (isExternal) {
    return (
      <a href={href} target={href.startsWith('mailto:') ? undefined : '_blank'} rel="noreferrer" className={classNames} style={style}>
        {children}
      </a>
    );
  }
  return (
    <GatbsyLink to={href} className={classNames} style={style} onClick={onClick}>
      {children}
    </GatbsyLink>
  );
};

export default Link;
