import React from 'react';
import { pxToRem, spacing, theme } from 'src/theme';
import useResizeObserver from 'use-resize-observer';
import SectionContainer from 'src/sections/shared/SectionContainer';
import Typography from 'src/components/core/Typography';
import Link from 'src/components/core/Link';
import SorryLayout from 'src/layouts/Sorry';
import { assetLink } from 'src/utils';

const SorryPage: React.FC = () => {
  const {
    height: footerHeight = 72,
    ref: footerRef,
  } = useResizeObserver<HTMLDivElement>();
  return (
    <SorryLayout footerRef={footerRef}>
      <SectionContainer
        style={{
          background: theme.palette.secondary.LIGHT_BLUE,
          minHeight: `calc(100vh - ${footerHeight}px)`,
          paddingTop: spacing(16),
        }}
      >
        <img
          src={assetLink('BizwiseLogoBlue.svg')}
          alt="Bizwise Logo"
          className="mb-32 mx-auto w-[200px]"
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="h2" color="blue" centered className="mb-6" style={{ fontWeight: 700 }}>
            Sorry, this website is temporarily unavailable.
          </Typography>
          <Typography variant="h3" color="blue" centered className="mb-2" style={{ maxWidth: pxToRem(1000) }}>
            If you&apos;re the owner of this domain, please contact us at
            {' '}
            <Link isExternal color="teal" href="mailto:billing@bizwise.com">
              billing@bizwise.com
            </Link>
            {' '}
            to get your website back online.
          </Typography>
          <Typography variant="p2" color="blue" centered>
            We&apos;re available for email support 24/7.
          </Typography>
        </div>
      </SectionContainer>
    </SorryLayout>
  );
};

export default SorryPage;
