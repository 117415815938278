import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  breakpoint, spacing, pxToRem, Theme,
} from 'src/theme';
import { assetLink, openIntercom } from 'src/utils';
import Typography from 'src/components/core/Typography';
import Link from 'src/components/core/Link';
import { CAREERS_LINK } from 'src/constants';

const useSmallStyles = createUseStyles<string, unknown, Theme>((theme) => ({
  footer: {
    position: 'relative',
    background: 'linear-gradient(180deg, #2546AA 0%, #244BA3 100%)',
    display: 'flex',
    flexWrap: 'wrap',
    gap: spacing(4),
    justifyContent: 'space-between',
    padding: `${spacing(6)} ${spacing(20)}`,
    [breakpoint.down('sm')]: {
      padding: `${spacing(6)} ${spacing(5)}`,
    },
  },
  bottomRowLink: {
    fontWeight: 300,
    color: theme.palette.primary.WHITE,
    '&:hover': {
      color: theme.palette.secondary.GRAY,
    },
  },
  pipe: {
    color: theme.palette.primary.WHITE,
    margin: `0 ${spacing(4)}`,
  },
}));

const useStyles = createUseStyles<string, unknown, Theme>((theme) => ({
  columns: {
    display: 'flex',
    flexWrap: 'wrap',
    [breakpoint.down('md')]: {
      flexDirection: 'column-reverse',
    },
  },
  linkColumns: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    flex: 1,
    [breakpoint.down('md')]: {
      marginBottom: spacing(-7.5),
      '& > *': {
        width: '48%',
        marginBottom: spacing(7.5),
      },
    },
  },
  linkColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  bottomRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    '& > *': {
      marginTop: spacing(5),
    },
  },
  bottomRowLinks: {
    display: 'flex',
  },
  link: {
    lineHeight: spacing(7),
    marginBottom: spacing(2),
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.WHITE,
    '&:hover': {
      color: theme.palette.secondary.GRAY,
    },
  },
  bottomRowLink: {
    fontWeight: 300,
    color: theme.palette.primary.WHITE,
    '&:hover': {
      color: theme.palette.secondary.GRAY,
    },
  },
  pipe: {
    color: theme.palette.primary.WHITE,
    margin: `0 ${spacing(4)}`,
  },
  logoColumn: {
    marginRight: spacing(50),
    [breakpoint.between('lg', 'xl')]: {
      marginRight: spacing(25),
    },
    [breakpoint.between('md', 'lg')]: {
      marginRight: spacing(12.5),
    },
    [breakpoint.down('md')]: {
      marginRight: spacing(7.5),
      marginTop: spacing(12.5),
    },
  },
  logo: {
    height: pxToRem(42),
  },
  footer: {
    position: 'relative',
    background: 'linear-gradient(180deg, #2546AA 0%, #244BA3 100%)',
    [breakpoint.up('xl')]: {
      padding: `${spacing(12.5)} ${spacing(50)}`,
    },
    [breakpoint.between('lg', 'xl')]: {
      padding: `${spacing(12.5)} ${spacing(25)}`,
    },
    [breakpoint.between('md', 'lg')]: {
      padding: `${spacing(12.5)} ${spacing(12.5)}`,
    },
    [breakpoint.between('sm', 'md')]: {
      padding: `${spacing(12.5)} ${spacing(12.5)}`,
    },
    [breakpoint.down('sm')]: {
      padding: `${spacing(12.5)} ${spacing(5)}`,
    },
  },
  hr: {
    backgroundColor: '#A3A3A3',
    opacity: 0.2,
    width: '100%',
    height: pxToRem(3.4),
    marginTop: spacing(8),
    marginBottom: spacing(5.5),
  },
}));

type LinkColumn = {
  label: string,
  links: {
    label: string,
    link?: string,
    icon?: string,
    onClick?: () => void,
  }[],
}

const linkColumns: LinkColumn[] = [
  {
    label: 'Bizwise',
    links: [
      {
        label: 'Home',
        link: '/',
      },
      {
        label: 'Pricing',
        link: '/pricing/',
      },
    ],
  },
  {
    label: 'Company',
    links: [
      {
        label: 'About Us',
        link: '/about/',
      },
      // TODO: Affiliates
      {
        label: 'Careers',
        link: CAREERS_LINK,
      },
    ],
  },
  {
    label: 'Resources',
    links: [
      {
        label: 'Customers',
        link: '/customers/',
      },
      {
        label: 'Support',
        onClick: openIntercom,
      },
      {
        label: 'Product Guides',
        // TODO: guides.bizwise.com
        link: 'https://guides.webveloper.com/',
      },
      {
        label: 'Security & Data Protection',
        link: '/policy/',
      },
    ],
  },
  {
    label: 'Follow Us',
    links: [
      {
        icon: assetLink('landing-ui/icons/FacebookIcon.svg'),
        label: 'Facebook',
        link: 'https://www.facebook.com/Bizwise-100437642425416',
      },
      {
        icon: assetLink('landing-ui/icons/TwitterIcon.svg'),
        label: 'Twitter',
        link: 'https://twitter.com/bizwise',
      },
      {
        icon: assetLink('landing-ui/icons/YouTubeIcon.svg'),
        label: 'YouTube',
        link: 'https://www.youtube.com/channel/UCHDhXEjTmdKRMOSJIBzEG4w/about',
      },
      {
        icon: assetLink('landing-ui/icons/InstagramIcon.svg'),
        label: 'Instagram',
        link: 'https://www.instagram.com/bizwisesmb',
      },
    ],
  },
];

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {
}

export const SmallFooter = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const classes = useSmallStyles();
  return (
    <div ref={ref}>
      <footer className={classes.footer}>
        <Typography variant="p3" color="white">
          Copyright ©
          {' '}
          {new Date().getFullYear()}
          {' '}
          Bizwise.
          {' '}
          <br className="inline-block md:hidden" />
          All Rights Reserved.
        </Typography>
        <div className="flex">
          <a href="https://bizwise.com/policy/" className={classes.bottomRowLink}>
            Privacy Policy
          </a>
          <div className={classes.pipe}>|</div>
          <a href="https://bizwise.com/policy/" className={classes.bottomRowLink}>
            Terms and Conditions
          </a>
        </div>
      </footer>
    </div>
  );
});

const Footer = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const classes = useStyles();
  return (
    <div ref={ref}>
      <footer className={classes.footer}>
        <div className={classes.columns}>
          <div className={classes.logoColumn}>
            <Link href="/">
              <img
                src={assetLink('BizwiseLogoWhite.svg')}
                alt="Bizwise Logo"
                className={classes.logo}
              />
            </Link>
            <Typography variant="p1" color="white" style={{ marginTop: spacing(9) }}>
              Build your business,
              <br />
              we&apos;ll handle the rest.
            </Typography>
          </div>
          <div className={classes.linkColumns}>
            {linkColumns.map((linkColumn) => (
              <div className={classes.linkColumn} key={linkColumn.label}>
                <Typography variant="p2" color="white" style={{ fontWeight: 700, marginBottom: spacing(3) }}>
                  {linkColumn.label}
                </Typography>
                {linkColumn.links.map(({
                  link, icon, label, onClick,
                }) => (
                  link ? (
                    <Link
                      className={classes.link}
                      href={link}
                      onClick={onClick}
                      isExternal={link?.startsWith('http')}
                      key={label}
                      isUnderlined={false}
                    >
                      {icon && (
                        <img src={icon} alt={`${label} Icon`} style={{ marginRight: spacing(4), width: spacing(5) }} />
                      )}
                      {label}
                    </Link>
                  ) : (
                    <button
                      className={classes.link}
                      type="button"
                      onClick={onClick}
                      key={label}
                    >
                      {icon && (
                        <img src={icon} alt={`${label} Icon`} style={{ marginRight: spacing(4), width: spacing(5) }} />
                      )}
                      {label}
                    </button>
                  )
                ))}
              </div>
            ))}
          </div>
        </div>
        <div className={classes.hr} />
        <div className={classes.bottomRow}>
          <Typography variant="p3" color="white">
            Copyright ©
            {' '}
            {new Date().getFullYear()}
            {' '}
            Bizwise.
            {' '}
            <br className="inline-block md:hidden" />
            All Rights Reserved.
          </Typography>
          <div className={classes.bottomRowLinks}>
            <a href="/policy/" className={classes.bottomRowLink}>
              Privacy Policy
            </a>
            <div className={classes.pipe}>|</div>
            <a href="/policy/" className={classes.bottomRowLink}>
              Terms and Conditions
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
});

export default Footer;
