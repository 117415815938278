import React, { FC } from 'react';
import { ThemeProvider } from 'react-jss';
import theme from 'src/theme';
import { SmallFooter } from 'src/sections/shared/Footer';

interface Props {
  footerRef?: (instance: HTMLDivElement | null) => void,
}

const SorryLayout: FC<Props> = ({
  children,
  footerRef,
}) => (
  <ThemeProvider theme={theme}>
    <main style={{ overflowX: 'hidden' }}>
      {children}
    </main>
    <SmallFooter ref={footerRef} />
  </ThemeProvider>
);

export default SorryLayout;
