import { useMediaQuery } from 'react-responsive';

const mobileBreakpoint = 959;
export const mobileMediaQuery = `@media screen and (max-width: ${mobileBreakpoint}px)`;
export const mobileDesktopQuery = `@media screen and (min-width: ${mobileBreakpoint + 1}px)`;

export function useIsMobile(): boolean {
  return useMediaQuery({ query: `(max-width: ${mobileBreakpoint}px)` });
}

const breakpoints = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
};

type Breakpoint = keyof typeof breakpoints;

export const breakpoint = Object.freeze({
  mobile: (): string => mobileMediaQuery,
  desktop: (): string => mobileDesktopQuery,
  up: (min: Breakpoint): string => `@media (min-width:${
    breakpoints[min]
  }px)`,
  down: (max: Breakpoint): string => `@media (max-width:${
    breakpoints[max]
  }px)`,
  between: (
    min: Breakpoint,
    max: Breakpoint,
  ): string => `@media (min-width:${
    breakpoints[min]
  }px) and (max-width:${
    breakpoints[max]
  }px)`,
});

export function pxToRem(size: number): string {
  return `${size / 16}rem`;
}

export function spacing(multiplier: number): string {
  const baseUnit = 4;
  return pxToRem(baseUnit * multiplier);
}

export const theme = {
  typography: {
    fontFamily: 'Open Sans, Helvetica',
    variants: {
      h1: {
        fontSize: pxToRem(60),
        lineHeight: '111.5%',
        fontWeight: 800,
        [mobileMediaQuery]: {
          fontSize: pxToRem(32),
          lineHeight: '125%',
        },
      },
      h2: {
        fontSize: pxToRem(40),
        lineHeight: '136%',
        fontWeight: 800,
        [mobileMediaQuery]: {
          fontSize: `${pxToRem(25)} !important`,
        },
      },
      h3: {
        fontSize: pxToRem(24),
        lineHeight: '136.7%',
        fontWeight: 600,
        [mobileMediaQuery]: {
          fontSize: pxToRem(20),
          lineHeight: '150%',
        },
      },
      p1: {
        fontSize: pxToRem(20),
        lineHeight: '150%',
        fontWeight: 400,
        [mobileMediaQuery]: {
          fontSize: pxToRem(18),
        },
      },
      p2: {
        fontSize: pxToRem(18),
        lineHeight: pxToRem(30),
        fontWeight: 400,
        [mobileMediaQuery]: {
          fontSize: pxToRem(16),
          lineHeight: pxToRem(23),
        },
      },
      p3: {
        fontSize: pxToRem(16),
        lineHeight: '137.5%',
        fontWeight: 400,
      },
    },
  },
  palette: {
    primary: {
      TEAL: '#00B6B6',
      TEAL_HOVER: '#048181',
      TEAL_HOVER_BACKGROUND: 'rgba(27, 179, 182, 0.1)',
      BLUE: '#13296B',
      BLUE_HOVER: '#4282FF',
      WHITE: '#FFFFFF',
      ERROR: '#9A0000',
      ERROR_BACKGROUND: '#FFF1F1',
    },
    secondary: {
      ORANGE: '#FDA14D',
      TEAL: '#05A4A4',
      TEAL_HOVER: '#048181',
      TEAL_BACKGROUND: '#EBF6F6',
      BLUE: '#5FBCFF',
      BLUE_2: '#244BA3',
      BLUE_3: '#6A87C5',
      GRAY: '#C4C4C4',
      GRAY_OUTLINE: '#B9B9B9',
      LIGHT_BLUE: '#EBF1FF',
      OFF_WHITE: '#F7F7F7',
      HR: 'rgba(0, 0, 0, 0.2)',
    },
    text: {
      PRIMARY: '#000000',
      SECONDARY: '#4F4F4F',
      BLACKISH: '#3C3C3C',
      BLUE_LINK: '#5283EB',
      BLUE_LINK_HOVER: '#2A499B',
    },
  },
};

export default theme;

export type Theme = typeof theme;
