import React, { CSSProperties } from 'react';
import cx from 'clsx';
import { createUseStyles, useTheme } from 'react-jss';
import { breakpoint, Theme } from 'src/theme';
import { assetLink } from 'src/utils';

type BackgroundStyle = 'light' | 'light-blue';

const useStyles = createUseStyles({
  root: {
    position: 'relative',
    zIndex: 0,
    overflow: 'hidden',
    [breakpoint.up('xl')]: {
      padding: '3vw 8vw',
    },
    [breakpoint.between('lg', 'xl')]: {
      padding: '3vw 4vw',
    },
    [breakpoint.between('md', 'lg')]: {
      padding: '3vw 4vw',
    },
    [breakpoint.between('sm', 'md')]: {
      padding: '3vw 4vw',
    },
    [breakpoint.down('sm')]: {
      padding: '8vw 4vw',
    },
  },
  shape: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    [breakpoint.down('lg')]: {
      display: 'none',
    },
  },
});

interface SectionContainerProps {
  className?: string,
  style?: CSSProperties,
  background?: BackgroundStyle,
  backgroundShape?: number,
  backgroundShapeBlendMode?: 'overlay' | 'soft-light',
  backgroundShapeZIndex?: number,
  children?: React.ReactNode,
}

const SectionContainer = React.forwardRef<HTMLDivElement, SectionContainerProps>(({
  className,
  style = {},
  background,
  backgroundShape,
  backgroundShapeBlendMode = 'overlay',
  backgroundShapeZIndex = -1, // -1 to be behind all of the children
  children,
}, ref) => {
  const classes = useStyles();
  const theme: Theme = useTheme();
  let backgroundStyle;
  switch (background) {
    case 'light': {
      backgroundStyle = theme.palette.primary.WHITE;
      break;
    }
    case 'light-blue': {
      backgroundStyle = theme.palette.secondary.LIGHT_BLUE;
      break;
    }
    default: {
      break;
    }
  }
  return (
    <div
      className={cx(classes.root, className)}
      style={{ background: backgroundStyle, ...style }}
      ref={ref}
    >
      {backgroundShape && (
        <div
          className={classes.shape}
          style={{
            mixBlendMode: backgroundShapeBlendMode,
            backgroundImage: `url('${assetLink(`landing-ui/background-shapes/${backgroundShape}.svg`)}')`,
            backgroundSize: 'cover',
            backgroundPositionX: 'start',
            backgroundPositionY: 'top',
            zIndex: backgroundShapeZIndex,
          }}
        />
      )}
      {children}
    </div>
  );
});

export default SectionContainer;
export type Props = SectionContainerProps;
