import React from 'react';
import cx from 'clsx';
import { createUseStyles } from 'react-jss';
import {
  pxToRem, spacing, breakpoint, Theme,
} from 'src/theme';

interface Props {
  centered?: boolean,
  className?: string,
}

interface StyleProps {
  centered: boolean,
}

const useStyles = createUseStyles<string, StyleProps, Theme>((theme) => ({
  underline: ({ centered }) => ({
    backgroundColor: theme.palette.secondary.ORANGE,
    width: pxToRem(120),
    height: pxToRem(7.7),
    marginLeft: centered ? 'auto' : 0,
    marginRight: centered ? 'auto' : 0,
  }),
  marginTop: {
    marginTop: spacing(4),
    [breakpoint.down('sm')]: {
      marginTop: spacing(4),
    },
  },
}));

const WithUnderline: React.FC<Props> = ({
  children,
  centered = false,
  className,
}) => {
  const classes = useStyles({ centered });
  return (
    <>
      {children}
      <div className={cx(classes.underline, classes.marginTop, className)} />
    </>
  );
};

export default WithUnderline;
